import { getImageReference } from "@tiicker/util/lib/contentful/helpers";
import { PerksForQualificationGroupAndBrandId } from "@tiicker/util/lib/contentful/queries";
import {
  BrandDetailDocument,
  ContentfulContentType,
  PerkDocument,
} from "@tiicker/util/lib/contentful/types";
import GroupedPerks, {
  getHoldingRequirement,
  getQualificationDetails,
  getQualificationText,
} from "components/BrandPage/PerksSection/GroupedPerks/GroupedPerks";
import { getPerkStatus } from "components/BrandPage/PerksSection/PerksSection";
import PerkCard, { PerkCardProps } from "components/core/PerkCard/PerkCard";
import { compact } from "lodash";
import React from "react";
import { useContentfulEntries } from "source/contentful/provider";
import styles from "./PerkGroupDisplay.module.scss";

type Props = {
  groupId: number;
  selectedPerkId: string;
  brandId: string;
  currentUserHoldingCount: number;
  currentPrice: number;
  clickToUrl?: boolean;
  splitEarly?: boolean;
  brand?: BrandDetailDocument;
  showBrandButton?: boolean;
};

const PerkGroupDisplay = (props: Props) => {
  const perks = useContentfulEntries<PerkDocument>(ContentfulContentType.Perk, {
    query: PerksForQualificationGroupAndBrandId(props.groupId, props.brandId),
  });

  if (perks.state !== "DONE") {
    return <></>;
  }

  const loadedPerks: PerkCardProps[] = compact(
    perks.result.items.map((perk) => {
      if (perk.fields.subPerk) {
        return undefined;
      }

      const { bubbleText, alreadyClaimed, availableToClaim, recurringDays } =
        getPerkStatus(
          perk,
          perks.result.items,
          props.currentUserHoldingCount,
          props.currentPrice
        );

      const image = getImageReference(perk.fields.image, perks.result.includes);
      const logo = getImageReference(
        perk.fields.brand.fields.companyLogoDark,
        perks.result.includes
      );

      return {
        id: perk.sys.id,
        title: perk.fields.title,
        imageUrl: image?.fields.file.url ?? "",
        brandLogo: logo?.fields.file.url ?? "",
        tickerSymbol: perk.fields.brand.fields.tickerSymbol,
        className: styles.card,
        cardClassName: styles.perkCard,
        imageClassName: styles.perkCardImage,
        noAnimate: true,
        qualiType: getQualificationText(perk.fields),
        qualiText: getQualificationDetails(perk.fields),
        holdingRequirement: getHoldingRequirement(perk.fields),
        qualificationGroup: perk.fields.qualificationGroup ?? undefined,
        alreadyClaimed,
        availableToClaim,
        recurringDays,
        notQualifiedText: bubbleText,
        clickToPerkUrl: props.clickToUrl,
        qualifyingBrands:
          perk.fields.qualifyingBrands &&
          perk.fields.qualifyingBrands.length > 0
            ? perk.fields.qualifyingBrands.map((x) => x.fields.brandName)
            : undefined,
        limitedQuantityNumber: perk.fields.limitedQuantityNumber,
        perkType: perk.fields.perkType ?? undefined,
      };
    })
  );

  return (
    <div>
      <GroupedPerks
        perks={loadedPerks}
        includes={perks.result.includes}
        allSelectable={false}
        isOnlyGroup={false}
        holdingCount={props.currentUserHoldingCount}
        currentPrice={props.currentPrice / props.currentUserHoldingCount}
        keyValue={`grouped-perks-${props.selectedPerkId}`}
        currentSelectedPerkId={props.selectedPerkId}
        splitEarly={props.splitEarly}
        brand={props.brand}
        showBrandButton={props.showBrandButton}
      />
    </div>
  );
};

export default PerkGroupDisplay;
