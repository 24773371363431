import { UserFragment } from "$gql/fragments/general/User.gen";
import {
  BrandDetailDocument,
  PerkDocument,
} from "@tiicker/util/lib/contentful/types";
import { formatCurrency } from "@tiicker/util/lib/formats";
import classnames from "classnames";
import PerkCard, { PerkCardProps } from "components/core/PerkCard/PerkCard";
import React from "react";
import { useFetchUser } from "source/hooks";
import GroupDetails from "./GroupDetails";
import styles from "./GroupedPerks.module.scss";

type Props = {
  allSelectable: boolean;
  isOnlyGroup: boolean;
  perks: PerkCardProps[];
  includes: any;
  holdingCount: number;
  currentPrice: number;
  keyValue?: string;
  currentSelectedPerkId?: string;
  splitEarly?: boolean;
  brand?: BrandDetailDocument;
  showBrandButton?: boolean;
};

export const getQualificationText = (perk: PerkDocument): string => {
  return perk.qualificationType === "PortfolioValue"
    ? "Portfolio Value Required"
    : perk.qualificationType === "ShareValue"
    ? "Value Required"
    : "Shares required";
};

export const getQualificationDetails = (perk: PerkDocument): string => {
  return perk.qualificationType === "PortfolioValue"
    ? formatCurrency(Number(perk.totalPortfolioValue))
    : perk.qualificationType === "ShareValue"
    ? formatCurrency(Number(perk.shareDollarValue))
    : `${perk.numberOfSharesRequired.toLocaleString()} ${
        perk.numberOfSharesRequired === 1 ? "share" : "shares"
      }`;
};
export const getHoldingRequirement = (perk: PerkDocument): string => {
  return perk.numberOfWeeksHeldRequired === 0
    ? "No requirement"
    : perk.numberOfWeeksHeldRequired === 1
    ? `${perk.numberOfWeeksHeldRequired} week`
    : `${perk.numberOfWeeksHeldRequired} weeks`;
};

const GroupedPerks = (props: Props) => {
  const user = useFetchUser();
  return (
    <div
      className={styles.container}
      key={`${props.keyValue ?? "grouped-perk"}`}
    >
      <GroupDetails
        allAvailable={props.allSelectable}
        isOnlyGroup={props.isOnlyGroup}
        numOfPerks={props.perks.length}
        holdingCount={props.holdingCount}
        currentPrice={props.currentPrice}
        keyValue={props.keyValue}
        splitEarly={props.splitEarly}
        brand={props.brand}
        includes={props.includes}
        user={user.user}
        showBrandButton={props.showBrandButton}
      />
      <div
        className={styles.perksContainer}
        key={`perk-container-${props.keyValue}`}
      >
        {props.perks.map((perk) => {
          if (perk.perkType === "Coming Soon") {
            return (
              <div className={classnames(styles.selectedCard, styles.card)}>
                <div className={styles.overlayColor} />
                <p className={styles.currentSelectionOverlay}>Coming Soon</p>
                <PerkCard
                  {...perk}
                  className={styles.fullHeightCard}
                  cardClassName={styles.perkCard}
                  imageClassName={styles.perkCardImage}
                  noAnimate
                />
              </div>
            );
          }
          if (props.currentSelectedPerkId === perk.id) {
            return (
              <div className={classnames(styles.selectedCard, styles.card)}>
                <div className={styles.overlayColor} />
                <p className={styles.currentSelectionOverlay}>
                  Current Selection
                </p>
                <PerkCard
                  {...perk}
                  className={styles.fullHeightCard}
                  cardClassName={styles.perkCard}
                  imageClassName={styles.perkCardImage}
                  noAnimate
                />
              </div>
            );
          }

          return (
            <PerkCard
              {...perk}
              className={styles.card}
              cardClassName={styles.perkCard}
              imageClassName={styles.perkCardImage}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GroupedPerks;
