import { getImageReference } from "@tiicker/util/lib/contentful/helpers";
import {
  BrandDetailDocument,
  PerkDocument,
} from "@tiicker/util/lib/contentful/types";
import { PerkCardProps } from "components/core/PerkCard/PerkCard";
import { useUser } from "components/UserContext/UserContext";
import { Entry, EntryCollection } from "contentful";
import { compact, groupBy } from "lodash";
import React from "react";
import GroupedPerks, {
  getHoldingRequirement,
  getQualificationDetails,
  getQualificationText,
} from "./GroupedPerks/GroupedPerks";
import styles from "./PerksSection.module.scss";
import { formatCurrency } from "@tiicker/util/lib/formats";
import * as DateIso from "@tiicker/util/lib/date-iso";
import { UserFragment } from "$gql/fragments/general/User.gen";

type Props = {
  tickerSymbol: string;
  brandPerks: EntryCollection<PerkDocument>;
  owningPerks: EntryCollection<PerkDocument>;
  currentUserHoldingCount: number;
  currentPrice: number;
  brand?: BrandDetailDocument;
  user: UserFragment | null | undefined;
  /*buyDate: Date; //TODO: Fix for TiiCKER-196
  sellDate: Date;
  buyTransactionID: number;*/
};

export const getPerkStatus = (
  perk: Entry<PerkDocument>,
  allPerks: Entry<PerkDocument>[],
  currentUserHoldingCount?: number,
  currentPrice?: number
  /*buyDate?: Date, //TODO: Fix for TiiCKER-196
  sellDate?: Date,
  buyTransactionID?: number*/
) => {
  const userContext = useUser();
  const availableToClaim = userContext.state.availablePerks.some(
    (x) => x.perkId.toLowerCase() === perk.sys.id.toLowerCase()
  );
  const alreadyClaimed =
    userContext.state.claimedPerkIds.some(
      (x) => x.toLowerCase() === perk.sys.id.toLowerCase()
    ) ||
    userContext.state.potentialFraudPerkIds.some(
      (x) => x.toLocaleLowerCase() === perk.sys.id.toLowerCase()
    );

  const group = perk.fields.qualificationGroup;

  const otherPerkClaimedInGroup =
    !availableToClaim && !alreadyClaimed && group
      ? allPerks.some(
          (p) =>
            userContext.state.claimedPerkIds.some(
              (id) => id.toLocaleLowerCase() === p.sys.id.toLowerCase()
            ) && p.fields.qualificationGroup === group
        )
      : false;

  const recurring = userContext.state.recurring.find(
    (recurring) => recurring.perkId === perk.sys.id
  );

  const recurringDays =
    recurring && !otherPerkClaimedInGroup
      ? DateIso.differenceInDays(recurring.recurringDate, DateIso.today())
      : undefined;

  /*const isDateLessThanWeekBeforeDate = (firstDate, seoondDate) => { //TODO: Fix for TiiCKER-196
    const oneWeekInMilliseconds =
      perk.fields.numberOfWeeksHeldRequired * (7 * 24 * 60 * 60 * 1000); // 7 days in milliseconds

    const differenceInMilliseconds = Math.abs(firstDate - seoondDate);

    return differenceInMilliseconds < oneWeekInMilliseconds;
  };

  const isBuyDateAfterSellDate = (buyDate, sellDate) => {
    return buyDate > sellDate;
  };*/

  const bubbleText =
    !currentUserHoldingCount || !currentPrice || otherPerkClaimedInGroup
      ? ""
      : !availableToClaim && !alreadyClaimed && currentUserHoldingCount
      ? perk.fields.qualificationType === "PortfolioValue"
        ? `${formatCurrency(
            (perk.fields.totalPortfolioValue ?? 0) -
              userContext.state.totalPortfolioValue
          )} More Required`
        : perk.fields.qualificationType === "ShareValue" ||
          perk.fields.numberOfSharesRequired - currentUserHoldingCount < 0
        ? // TODO: make front end and backend use consistant pricing so this can't possibly be negative
          ""
        : // ? `${formatCurrency(
          //     (perk.fields.shareDollarValue ?? 0) -
          //       currentUserHoldingCount * currentPrice
          //   )} More in Shares to Qualify`
          `${parseFloat(
            (
              perk.fields.numberOfSharesRequired - currentUserHoldingCount
            ).toFixed(1)
          ).toLocaleString()} More Shares to Qualify`
      : "";

  /*const bubbleTextHoldingPeriodNotMet = //TODO: Fix for TiiCKER-196
    bubbleText.length === 0
      ? typeof buyTransactionID !== "undefined" && buyTransactionID < 0
        ? ""
        : isBuyDateAfterSellDate(buyDate, sellDate)
        ? ""
        : isDateLessThanWeekBeforeDate(new Date(), buyDate)
        ? "Holding period not met"
        : ""
      : "";*/
  const qualificationGroup = perk.fields.qualificationGroup;

  return {
    availableToClaim,
    alreadyClaimed,
    recurringDays,
    bubbleText,
    /*bubbleTextHoldingPeriodNotMet,*/ //TODO: Fix for TiiCKER-196
    qualificationGroup,
  };
};

const PerksSection = (props: Props) => {
  const perks: PerkCardProps[] = compact(
    props.brandPerks.items.map((perk) => {
      if (perk.fields.subPerk) {
        return undefined;
      }

      const {
        bubbleText,
        /*bubbleTextHoldingPeriodNotMet,*/ //TODO: Fix for TiiCKER-196
        alreadyClaimed,
        availableToClaim,
        recurringDays,
      } = getPerkStatus(
        perk,
        props.brandPerks.items,
        props.currentUserHoldingCount,
        props.currentPrice
        /*props.buyDate, //TODO: Fix for TiiCKER-196
        props.sellDate,
        props.buyTransactionID*/
      );

      const image = getImageReference(
        perk.fields.image,
        props.brandPerks.includes
      );
      const logo = getImageReference(
        perk.fields.brand.fields.companyLogoDark,
        props.brandPerks.includes
      );

      return {
        id: perk.sys.id,
        title: perk.fields.title,
        imageUrl: image?.fields.file.url ?? "",
        brandLogo: logo?.fields.file.url ?? "",
        tickerSymbol: perk.fields.brand.fields.tickerSymbol,
        className: styles.card,
        cardClassName: styles.perkCard,
        imageClassName: styles.perkCardImage,
        noAnimate: true,
        qualiType: getQualificationText(perk.fields),
        qualiText: getQualificationDetails(perk.fields),
        holdingRequirement: getHoldingRequirement(perk.fields),
        qualificationGroup: perk.fields.qualificationGroup ?? undefined,
        alreadyClaimed,
        availableToClaim,
        recurringDays,
        notQualifiedText: bubbleText,
        /*notQualifiedbubbleTextHoldingPeriodNotMet:
          bubbleTextHoldingPeriodNotMet,*/ //TODO: Fix for TiiCKER-196
        qualifyingBrands:
          perk.fields.qualifyingBrands &&
          perk.fields.qualifyingBrands.length > 0
            ? perk.fields.qualifyingBrands.map((x) => x.fields.brandName)
            : undefined,
        limitedQuantityNumber: perk.fields.limitedQuantityNumber,
        perkType: perk.fields.perkType ?? undefined,
      };
    })
  );
  const owningPerks: PerkCardProps[] = compact(
    props.owningPerks.items.map((perk) => {
      if (perk.fields.subPerk) {
        return undefined;
      }
      const {
        bubbleText,
        /*bubbleTextHoldingPeriodNotMet,*/ //TODO: Fix for TiiCKER-196
        alreadyClaimed,
        availableToClaim,
        recurringDays,
      } = getPerkStatus(
        perk,
        props.owningPerks.items,
        props.currentUserHoldingCount,
        props.currentPrice
      );

      const image = getImageReference(
        perk.fields.image,
        props.owningPerks.includes
      );
      const logo = getImageReference(
        perk.fields.brand.fields.companyLogoDark,
        props.owningPerks.includes
      );

      return {
        id: perk.sys.id,
        title: perk.fields.title,
        imageUrl: image?.fields.file.url ?? "",
        brandLogo: logo?.fields.file.url ?? "",
        tickerSymbol: perk.fields.brand.fields.tickerSymbol,
        className: styles.card,
        cardClassName: styles.perkCard,
        imageClassName: styles.perkCardImage,
        noAnimate: true,
        qualiType: getQualificationText(perk.fields),
        qualiText: getQualificationDetails(perk.fields),
        holdingRequirement: getHoldingRequirement(perk.fields),
        qualificationGroup: perk.fields.qualificationGroup ?? undefined,
        alreadyClaimed,
        availableToClaim,
        recurringDays,
        notQualifiedText: bubbleText,
        /*notQualifiedbubbleTextHoldingPeriodNotMet:
          bubbleTextHoldingPeriodNotMet,*/ //TODO: Fix for TiiCKER-196
        qualifyingBrands:
          perk.fields.qualifyingBrands &&
          perk.fields.qualifyingBrands.length > 0
            ? perk.fields.qualifyingBrands.map((x) => x.fields.brandName)
            : undefined,
        limitedQuantityNumber: perk.fields.limitedQuantityNumber,
        perkType: perk.fields.perkType ?? undefined,
      };
    })
  );

  const allPerks = perks.concat(owningPerks);

  const perksGroupedByQualificationGroup = groupBy(
    allPerks,
    "qualificationGroup"
  );

  const { undefined: ungroupedPerks, ...groupedPerks } =
    perksGroupedByQualificationGroup;
  const finalUngroupedPerks = ungroupedPerks || [];

  return (
    <div className="container">
      <div className={styles.perkContainer}>
        {Object.keys(groupedPerks).map((qualificationGroupKey) => {
          const group = groupedPerks[qualificationGroupKey];

          return (
            <GroupedPerks
              perks={group}
              isOnlyGroup={false}
              includes={props.brandPerks.includes}
              allSelectable={false}
              holdingCount={props.currentUserHoldingCount}
              currentPrice={props.currentPrice}
              keyValue={`grouped-perks-${qualificationGroupKey}`}
              brand={props.brand}
            />
          );
        })}
        {finalUngroupedPerks.length !== 0 && (
          <GroupedPerks
            perks={finalUngroupedPerks}
            isOnlyGroup={Object.keys(groupedPerks).length === 0}
            includes={props.brandPerks}
            allSelectable
            holdingCount={props.currentUserHoldingCount}
            currentPrice={props.currentPrice}
            keyValue={`ungrouped-perks-section`}
            brand={props.brand}
          />
        )}
      </div>
    </div>
  );
};

export default PerksSection;
