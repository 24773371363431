import { BrandDetailDocument } from "@tiicker/util/lib/contentful/types";
import Button from "components/core/Button/Button";
import Container from "components/core/Layout/Container/Container";
import Modal from "components/Modal/Modal";
import React, { useState } from "react";
import ManualUploadUI from "../ManualUploadUI";
import styles from "./ManualShareUpload.module.scss";

type Props = {
  brand?: BrandDetailDocument;
  includes: any;
  userId: number;
};

const ManualShareUpload = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  if (!props.brand) {
    return <></>;
  }

  return (
    <div>
      <Button onClick={openModal} className={styles.uploadButton}>
        Upload Holdings
      </Button>
      <Modal visible={showModal} onClose={closeModal}>
        <Container>
          <ManualUploadUI
            brand={props.brand}
            includes={props.includes}
            closeModal={closeModal}
            userId={props.userId}
          />
        </Container>
      </Modal>
    </div>
  );
};

export default ManualShareUpload;
