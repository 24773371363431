import { GetStoreAutoLoginToken } from "$gql/queries/general/GetStoreAutoLoginToken.gen";
import Button from "components/core/Button/Button";
import LoadingBlock from "components/Loading/LoadingBlock";
import React from "react";
import { useQueryBundle } from "source/hooks";

interface Props {
  redemptionUrl: string;
}

const AxomoGiveawayResult = (props: Props) => {
  const firstParts = props.redemptionUrl.split("//");
  const secondParts = firstParts.length > 1 ? firstParts[1].split(".") : [];
  const subdomain = secondParts[0] || "";

  const getAutoLoginToken = useQueryBundle(GetStoreAutoLoginToken, {
    variables: { tickerSymbolOrSubdomain: subdomain },
  });

  if (getAutoLoginToken.state !== "DONE") {
    return <LoadingBlock />;
  }

  const token = getAutoLoginToken.data.getStoreAutoLoginToken?.token || "";
  if (token === "") {
    return (
      <p>There was an error fetching your coupon. Please contact support.</p>
    );
  }

  return (
    <div>
      <p>You can click the button below to use your coupon code!</p>
      <p>
        <Button href={`${props.redemptionUrl}?auto=${token}`} target="_blank">
          Claim Now!
        </Button>
      </p>
    </div>
  );
};

export default AxomoGiveawayResult;
