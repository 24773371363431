import {
  getImageReference,
  getItemReference,
} from "@tiicker/util/lib/contentful/helpers";
import {
  BrandDetailDocument,
  CompanySubBrand,
  ContentfulContentType,
} from "@tiicker/util/lib/contentful/types";
import { compact } from "lodash";
import styles from "./SubBrandLogos.module.scss";
import { useContentfulEntries } from "source/contentful/provider";

type Props = {
  brandId: string;
};

const SubBrandLogos = (props: Props) => {
  const brand = useContentfulEntries<BrandDetailDocument>(
    ContentfulContentType.BrandDetail,
    {
      query: {
        content_type: ContentfulContentType.BrandDetail,
        "sys.id": props.brandId,
      },
    }
  );

  if (brand.state !== "DONE" || brand.result.items.length === 0) {
    return <></>;
  }

  const brandDetail = brand.result.items[0];

  const subBrands = compact(
    brandDetail.fields.subBrands.map((sb) =>
      getItemReference<CompanySubBrand>(sb, brand.result.includes)
    )
  );

  const logos = subBrands.map((sb) =>
    getImageReference(sb.fields.brandLogo, brand.result.includes)
  );

  return (
    <div className={styles.container}>
      {logos.map((logo) => (
        <img src={logo?.fields.file.url} className={styles.logo} />
      ))}
    </div>
  );
};

export default SubBrandLogos;
