import { GetUserCouponCodeForPerk } from "$gql/queries/general/GetUserCouponCodeForPerk.gen";
import { PerkDocument } from "@tiicker/util/lib/contentful/types";
import classNames from "classnames";
import Markdown from "components/Markdown/Markdown";
import PollPerkCertificate from "./PollPerkCertificate";
import React from "react";
import { useQueryBundle } from "source/hooks";
import AxomoGiveawayResult from "./AxomoGiveawayResult/AxomoGiveawayResult";
import styles from "./ClaimedPerkDisplay.module.scss";
import CouponCodeResults from "./CouponCode/CouponCodeResults";
import StorePerkResults from "./Store/StorePerkResults";

type Props = {
  perkId: string;
  userId: number;
  perk: PerkDocument;
  tickerSymbol: string;
  brandName: string;
  showPerkTitle?: boolean;
  className?: string;
};

const ClaimedPerkDisplay = (props: Props) => {
  const couponCodePerk =
    props.perk.perkType === "CouponCode" ||
    props.perk.perkType === "Tango" ||
    props.perk.perkType === "Axomo Item Giveaway";

  const perkType = props.perk.perkType;

  // Only load the coupon code if if a perk type the has one when claimed
  const claimedPerkAmountQuery = useQueryBundle(GetUserCouponCodeForPerk, {
    variables: { perkId: props.perkId, userId: props.userId },
    skip: !couponCodePerk,
  });

  const couponDetails =
    claimedPerkAmountQuery.state === "DONE" &&
    claimedPerkAmountQuery.data.getUserCouponCodeForPerk
      ? claimedPerkAmountQuery.data.getUserCouponCodeForPerk
      : undefined;

  return (
    <div className={classNames(props.className, styles.claimedBox)}>
      {perkType === "Certificate" && (
        <>
          {props.perk.redemptionDetails && (
            <Markdown markdownText={props.perk.redemptionDetails} />
          )}
          <PollPerkCertificate userId={props.userId} perkId={props.perkId} />
        </>
      )}

      {perkType === "Axomo Item Giveaway" &&
        claimedPerkAmountQuery.state === "DONE" &&
        claimedPerkAmountQuery.data.getUserCouponCodeForPerk?.redemptionUrl && (
          <AxomoGiveawayResult
            redemptionUrl={
              claimedPerkAmountQuery.data.getUserCouponCodeForPerk
                ?.redemptionUrl
            }
          />
        )}
      {perkType === "Manual" &&
        (props.perk.redemptionDetails ? (
          <Markdown markdownText={props.perk.redemptionDetails} />
        ) : (
          <p>
            The TiiCKER team will be in contact via the email address associated
            with your TiiCKER account to fulfill your perk.
          </p>
        ))}

      {(perkType === "CouponCode" || perkType === "Tango") && couponDetails && (
        <CouponCodeResults
          couponCode={couponDetails.couponCode ?? ""}
          redemptionDetails={
            couponDetails.redemptionInstructions ??
            props.perk.redemptionDetails ??
            ""
          }
          link={couponDetails.redemptionUrl ?? undefined}
          title={couponDetails.couponLabel ?? undefined}
          showPerkTitle={props.showPerkTitle}
          perkTitle={props.perk.title}
        />
      )}

      {perkType === "Store" && props.perk.storeUrl && (
        <StorePerkResults
          redemptionDetails={props.perk.redemptionDetails ?? undefined}
          storeUrl={props.perk.storeUrl}
          tickerSymbol={props.tickerSymbol}
          brandName={props.brandName}
        />
      )}
    </div>
  );
};

export default ClaimedPerkDisplay;
