import RedirectToFixOAuth from "components/Dashboard/AccountIssue/RedirectToFixOAuth";
import { useUser } from "components/UserContext/UserContext";
import { useState } from "react";
import styles from "./AccountBadState.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkSlash } from "@fortawesome/sharp-light-svg-icons";

type Props = {
  brokerageName: string;
  loginId: number;
  plaidInstitutionId?: string;
};

const AccountBadState = (props: Props) => {
  const [launchDirectReconnect, setLaunchDirectReconnect] =
    useState<boolean>(false);
  const userContext = useUser();
  const click = () => {
    if (props.plaidInstitutionId) {
      userContext.openPlaid(props.loginId);
    } else {
      setLaunchDirectReconnect(true);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <FontAwesomeIcon className={styles.brokenLink} icon={faLinkSlash} />
        <div className={styles.detailContainer}>
          <h5 className={styles.uhOhTitle}>Uh Oh!</h5>
          <div>
            <p className={styles.description}>
              There seems to be a problem with your{" "}
              <span className={styles.brokerageName}>
                {props.brokerageName}
              </span>{" "}
              brokerage connection. <a onClick={click}>Lets fix it!</a>
            </p>
          </div>
        </div>
      </div>
      {launchDirectReconnect && <RedirectToFixOAuth loginId={props.loginId} />}
    </>
  );
};

export default AccountBadState;
