import { ClaimPerkOrPerkTier } from "$gql/mutations/general/ClaimPerkOrPerkTier.gen";
import {
  ClaimedPerkOrTierOptionArgs,
  PlaidLoginStatus,
  UserStockHoldingResponse,
} from "$gql/types.gen";
import {
  BrandDetailDocument,
  PerkDocument,
} from "@tiicker/util/lib/contentful/types";
import {
  recordEvent,
  recordStackAdaptPerkClaimed,
} from "components/Analytics/AnalyticsRecorder";
import {
  getHoldingRequirement,
  getQualificationDetails,
} from "components/BrandPage/PerksSection/GroupedPerks/GroupedPerks";
import Button from "components/core/Button/Button";
import Modal from "components/Modal/Modal";
import { useUser } from "components/UserContext/UserContext";
import React, { useState, useEffect } from "react";
import {
  useAuth,
  useFetchUser,
  useMutationBundle,
  useQueryBundle,
} from "source/hooks";
import PerkOptions, { PerkOption } from "./Options/PerkOptions";
import styles from "./PerkMegaModal.module.scss";
import { triggerPerkClaim } from "source/analytics-events";
import ClaimedPerkDisplay from "./ClaimedDisplay/ClaimedPerkDisplay";
import { Entry } from "contentful";
import BundleOptions from "./Options/BundleOptions";
import BundleClaimedPerkDisplay from "./ClaimedDisplay/BundleClaimedPerkDisplay";
import PerkGroupDisplay from "./Group/PerkGroupDisplay";
import classNames from "classnames";
import InsightShare from "components/InsightShare/InsightShare";
import LoadingBlock from "components/Loading/LoadingBlock";
import PerkDetails from "./Details/PerkDetails";
import AccountBadState from "./AccountBadState/AccountBadState";
import NotQualified from "./NotQualified/NotQualified";
import ConnectAccounts from "./ConnectAccounts/ConnectAccounts";
import SubBrandLogos from "./SubBrandLogos/SubBrandLogos";
import { GetPerkClaimPending } from "$gql/queries/general/GetPerkClaimPending.gen";
import Message, { MessageType } from "components/core/Message/Message";
import LoadingCircle from "components/LoadingCircle/LoadingCircle";

export type PerkMegaModalUIProps = {
  isOpen: boolean;
  onClose(): void;
} & (
  | { isLoading: true }
  | {
      isLoading?: false;
      perkId: string;
      perk: PerkDocument;
      brand: BrandDetailDocument;
      perkImageUrl: string;
      brandLogoUrl: string;
      holdingData: UserStockHoldingResponse;
      includes: any;
      perkEntry: Entry<PerkDocument>;
      subPerks: Entry<PerkDocument>[];
      brandId: string;
      qualifyingBrands?: BrandDetailDocument[];
      claimCount: number;
      openManualShareUpload?: () => void;
    }
);

const PerkMegaModalUI = (props: PerkMegaModalUIProps) => {
  const [showAllQualifyingBrands, setShowAllQualifyingBrands] =
    useState<boolean>(false);
  const [claimClicked, setClaimClicked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isCheckboxRendered, setCheckboxRendered] = useState<boolean>(false);
  const [isCheckboxChecked, setCheckboxChecked] = useState<boolean>(false);

  const userContext = useUser();
  const user = useFetchUser();
  const auth = useAuth();

  const perkClaimPending = useQueryBundle(GetPerkClaimPending, {
    variables: {
      perkId: !props.isLoading ? props.perkId : "",
      userId: user.user ? user.user.id : 0,
    },
  });

  const claimAttemptNeedsApproval =
    perkClaimPending.state === "DONE" &&
    perkClaimPending.data.getPerkClaimPending;

  const [perkOptions, setPerkOptions] = useState<PerkOption[]>([]);

  const [selectedOptions, setSelectedOptions] = useState<
    ClaimedPerkOrTierOptionArgs[]
  >([]);

  useEffect(() => {
    if (!props.isOpen) {
      // Clear options closing and opening modal
      setSelectedOptions([]);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (
      !props.isLoading &&
      props.brand?.brandDetailOverflow?.fields?.partnerTitle
    ) {
      setCheckboxRendered(true);
    } else {
      setCheckboxRendered(false);
    }
    // I only have this in here because of the Union type that the props are declared with. I know of no way to get around this issue.
    // @ts-ignore
  }, [props.brand, props.isLoading]);

  const canClaimPerk = props.isLoading
    ? false
    : userContext.state.availablePerks.some(
        (perk) => perk.perkId === props.perkId
      );
  const alreadyClaimedPerk = props.isLoading
    ? false
    : userContext.state.claimedPerkIds.some((id) => id === props.perkId);
  const claimedPerk = props.isLoading
    ? undefined
    : userContext.state.claimedPerks.find((cp) => cp.perkId === props.perkId);
  const potentialFraud = props.isLoading
    ? false
    : userContext.state.potentialFraudPerkIds.some((id) => id === props.perkId);
  const isBundle = !props.isLoading
    ? props.perk.perkType === "Tier Perk"
    : undefined;

  const optionsNeedToSelect =
    props.isLoading || perkOptions.length === 0
      ? false
      : !perkOptions.every((opt) =>
          !opt.conditionalDisplay || opt.conditionalDisplay.length === 0
            ? // If it is not conditional display check to make sure there is a selected option
              selectedOptions.some((sel) => sel.option === opt.title)
            : // If it is conditional display, make sure the conditional has been met
            opt.conditionalDisplay.every((conditional) =>
                selectedOptions.some(
                  (sel) => sel.value.toLowerCase() === conditional.toLowerCase()
                )
              )
            ? selectedOptions.some((sel) => sel.option === opt.title)
            : true
        );

  const [claimPerk] = useMutationBundle(ClaimPerkOrPerkTier);

  const onClaimPerk = () => {
    // assuming you are logged in, not loading, do not need to select options
    // and qualify for this perk you can claim
    if (!user.user || props.isLoading || optionsNeedToSelect || !canClaimPerk) {
      return;
    }
    setClaimClicked(true);

    try {
      claimPerk({
        variables: {
          perkId: props.perkId,
          userId: user.user.id,
          options: selectedOptions,
          owningBrandTicker: props.brand.tickerSymbol,
        },
        refetchQueries: ["GetUserPerks"],
      }).then(() => {
        perkClaimPending.refetch();
      });
      // result.data?.claimPerkOrPerkTier.map((x) => {
      //   x.id && dispatch({ type: "claimed", claimId: x.id });
      // });

      recordEvent();
      recordStackAdaptPerkClaimed(props.brand.tickerSymbol);
      triggerPerkClaim(props.brand.tickerSymbol, props.perk.title);
    } catch (e) {
      console.error("ERROR", e);
      setErrorMessage("Problem claiming Perk");
    }
  };

  const isQualified =
    !props.isLoading &&
    userContext.state.availablePerks.some(
      (perk) => perk.perkId === props.perkId
    );

  const claimedCertificate =
    alreadyClaimedPerk &&
    claimedPerk &&
    !props.isLoading &&
    props.perk.perkType === "Certificate";

  const userLoginsInBadState = userContext.state.logins.filter(
    (login) =>
      login.plaidStatus === PlaidLoginStatus.NeedsAuthentication ||
      login.plaidStatus === PlaidLoginStatus.NeedsReconnect
  );

  const userAccountsConnected =
    userContext.state.logins.filter((x) => x.inactive === false).length > 0;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxChecked(event.target.checked);
  };

  // Checking the conditions for checkbox rendering, similar to the "Claim Perk" button.
  const shouldRenderCheckbox =
    userContext.state.userId &&
    (!alreadyClaimedPerk || canClaimPerk) &&
    !claimAttemptNeedsApproval &&
    !potentialFraud &&
    isCheckboxRendered &&
    isQualified &&
    !optionsNeedToSelect &&
    !claimClicked;

  return (
    <Modal
      className={styles.PerkMegaModal__modalWrapper}
      visible={props.isOpen}
      onClose={props.onClose}
      modalClass={styles.PerkMegaModal__modalClass}
      modalClassName={styles.PerkMegaModal__topModal}
    >
      {errorMessage && (
        <Message message={errorMessage} type={MessageType.ERROR} />
      )}
      {props.isLoading ? (
        <LoadingBlock />
      ) : (
        <>
          <div className={classNames("row", styles.PerkMegaModal__row)}>
            <div className="col-lg-6">
              <div className={styles.PerkMegaModal__leftColumnWrapper}>
                <div className={styles.PerkMegaModal__logoContainer}>
                  <img
                    className={styles.PerkMegaModal__brandLogo}
                    src={props.brandLogoUrl}
                  />
                  {props.perk.limitedQuantityNumber &&
                    props.perk.limitedQuantityNumber > 0 && (
                      <div className={styles.PerkMegaModal__limitedEdition}>
                        <img
                          src="/icons/star.png"
                          className={styles.PerkMegaModal__star}
                        />{" "}
                        Limited
                      </div>
                    )}
                </div>
                <h2>{props.perk.title}</h2>
                {props.perk.showSubbrandsOnPerkModal && (
                  <SubBrandLogos brandId={props.brandId} />
                )}

                <PerkDetails
                  alreadyClaimedPerk
                  canClaimPerk
                  sharesHeld={props.holdingData.quantity}
                  perk={props.perk}
                  claimCount={props.claimCount}
                />

                {props.qualifyingBrands && props.qualifyingBrands.length > 0 && (
                  <div className={styles.PerkMegaModal__qualifyingBrands}>
                    <h5 className={styles.PerkMegaModal__qualifyingBrandsTitle}>
                      Qualifying Brands
                    </h5>
                    <p className={styles.PerkMegaModal__qualifyingBrandsText}>
                      {props.qualifyingBrands
                        .slice(
                          0,
                          showAllQualifyingBrands
                            ? props.qualifyingBrands.length
                            : 3
                        )
                        .map((x) => x.brandName)
                        .join(", ")}
                      {props.qualifyingBrands.length > 3 &&
                        !showAllQualifyingBrands && (
                          <>
                            {"... "}
                            <a onClick={() => setShowAllQualifyingBrands(true)}>
                              Show All
                            </a>
                          </>
                        )}
                    </p>
                  </div>
                )}
                {props.perk.showAddressOnModal && user.user && (
                  <>
                    <p>
                      Some items in your perk may be shipped to the address
                      associated with your profile.{" "}
                      <a href="/my-account">Click here</a> to update your
                      address information if necessary, before claiming your
                      perk.
                    </p>
                    <p className="perksModal__addressField">
                      {user.user.streetAddress1}
                    </p>
                    {user.user.streetAddress2 && (
                      <p className="perksModal__addressField">
                        {user.user.streetAddress2}
                      </p>
                    )}
                    <p className="perksModal__addressField">
                      {user.user.city ? `${user.user.city},` : ""}{" "}
                      {user.user.state} {user.user.zipCode}
                    </p>
                    {user.user.country !== "United States" ? (
                      <p className="perksModal__addressField">
                        {user.user.country}
                      </p>
                    ) : (
                      ""
                    )}
                  </>
                )}

                {!isBundle && canClaimPerk && (
                  <PerkOptions
                    perk={props.perkEntry}
                    includes={props.includes}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    perkId={props.perkId}
                    setPerkOptions={setPerkOptions}
                    loadedPerkOptions={perkOptions}
                  />
                )}

                {isBundle &&
                  (!alreadyClaimedPerk || canClaimPerk) &&
                  !claimAttemptNeedsApproval &&
                  !potentialFraud && (
                    <>
                      {canClaimPerk && (
                        <h4 className={styles.PerkMegaModal__bundleTitle}>
                          Select your options below to claim:
                        </h4>
                      )}
                      <BundleOptions
                        subPerks={props.perk.perkTierPerks}
                        includes={props.includes}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        setPerkOptions={setPerkOptions}
                        loadedPerkOptions={perkOptions}
                        canClaimPerk={canClaimPerk}
                      />
                    </>
                  )}

                {potentialFraud && (
                  <p>
                    The TiiCKER team will be in contact via the email address
                    associated with your TiiCKER account to fulfill your perk.
                  </p>
                )}

                {claimAttemptNeedsApproval && (
                  <p className={styles.PerkMegaModal__claimAttempt}>
                    Congratulations on your perk qualification! Thanks for being
                    a loyal shareholder. The TiiCKER team will send you perk
                    redemption details via the email address associated with
                    your TiiCKER account. Please allow up to 1 to 2 business
                    days for receipt.
                  </p>
                )}

                {alreadyClaimedPerk &&
                  !canClaimPerk &&
                  !claimAttemptNeedsApproval &&
                  user.user &&
                  isBundle && (
                    <BundleClaimedPerkDisplay
                      subPerks={props.perk.perkTierPerks}
                      tickerSymbol={props.brand.tickerSymbol}
                      userId={user.user.id}
                      brandName={props.brand.brandName}
                      perk={props.perk}
                    />
                  )}

                {alreadyClaimedPerk &&
                  !claimAttemptNeedsApproval &&
                  !canClaimPerk &&
                  user.user &&
                  !isBundle && (
                    <ClaimedPerkDisplay
                      perk={props.perk}
                      perkId={props.perkId}
                      tickerSymbol={props.brand.tickerSymbol}
                      userId={user.user.id}
                      brandName={props.brand.brandName}
                    />
                  )}

                {shouldRenderCheckbox && (
                  <div className={styles.PerkMegaModal__checkboxContainer}>
                    <p
                      className={styles.PerkMegaModal__checkboxContainer__title}
                    >
                      {props.brand.brandName} requests a valid email address and
                      other information to claim this perk.
                    </p>
                    <div
                      className={
                        styles.PerkMegaModal__checkboxContainer__optionContainer
                      }
                    >
                      <input
                        type="checkbox"
                        checked={isCheckboxChecked}
                        onChange={handleCheckboxChange}
                        data-cy="ShareEmailCheckbox"
                      />
                      <p
                        className={
                          styles.PerkMegaModal__checkboxContainer__optionContainer__agreement
                        }
                      >
                        By opting in, I agree and understand that the email
                        address and other information associated with my TiiCKER
                        account will be shared with {props.brand.brandName}.
                      </p>
                    </div>
                  </div>
                )}

                <div className={styles.PerkMegaModal__buttonWrapper}>
                  {/* If the user has not claimed a perk id and it is not available
                    we do not show claim perk button */}
                  <div className={styles.PerkMegaModal__claimAndShare}>
                    {!isQualified &&
                    props.brand.showManualStockUpload &&
                    user.user &&
                    !alreadyClaimedPerk &&
                    !claimAttemptNeedsApproval &&
                    !potentialFraud &&
                    props.openManualShareUpload ? (
                      <Button
                        className={styles.PerkMegaModal__claimButton}
                        type="primary"
                        onClick={props.openManualShareUpload}
                      >
                        Manually Validate Holidngs
                      </Button>
                    ) : userContext.state.userId ? (
                      (!alreadyClaimedPerk || canClaimPerk) &&
                      !claimAttemptNeedsApproval &&
                      !potentialFraud && (
                        <Button
                          className={styles.PerkMegaModal__claimButton}
                          type="primary"
                          disabled={
                            (isCheckboxRendered && !isCheckboxChecked) ||
                            !isQualified ||
                            optionsNeedToSelect ||
                            claimClicked
                          }
                          onClick={onClaimPerk}
                          dataCy="ClaimPerkButton"
                        >
                          {claimClicked ? (
                            <LoadingCircle />
                          ) : optionsNeedToSelect ? (
                            "Select Options Above"
                          ) : isQualified ? (
                            "Claim Perk"
                          ) : (
                            "Not Qualified for Perk"
                          )}
                        </Button>
                      )
                    ) : (
                      <Button
                        className={styles.PerkMegaModal__claimButton}
                        type="primary"
                        onClick={auth.smsSignUp}
                        dataCy="JoinNowButton"
                      >
                        Join Now
                      </Button>
                    )}
                    <InsightShare
                      title={
                        claimedCertificate
                          ? props.perk.metaTitle ?? ""
                          : props.perk.title
                      }
                      slug={props.perkId}
                      shareText="Share Perk"
                      urlOverride={
                        claimedCertificate
                          ? `${
                              typeof window !== "undefined" &&
                              window.location.host
                            }/perks/claimed/${claimedPerk.claimedPerkId}-${
                              props.perkId
                            }`
                          : `${
                              typeof window !== "undefined" &&
                              window.location.host
                            }/perks/${props.perkId}`
                      }
                    />
                  </div>
                  {userContext.state.userId && (
                    <>
                      {userLoginsInBadState.length > 0 &&
                        userLoginsInBadState.map((login) => (
                          <AccountBadState
                            brokerageName={login.institutionName}
                            loginId={login.id}
                            plaidInstitutionId={
                              login.plaidInstitutionId ?? undefined
                            }
                          />
                        ))}
                      {!canClaimPerk &&
                        !alreadyClaimedPerk &&
                        userAccountsConnected && (
                          <NotQualified
                            requirementText={getQualificationDetails(
                              props.perk
                            )}
                            holdingRequirement={getHoldingRequirement(
                              props.perk
                            )}
                            brandTicker={props.brand.tickerSymbol}
                            brandName={props.brand.brandName}
                          />
                        )}
                      {!userAccountsConnected && <ConnectAccounts />}
                    </>
                  )}
                  {!props.perk.qualificationGroup && (
                    <Button
                      arrow
                      type="secondary"
                      href={`/brand/${props.brand.tickerSymbol}`}
                      className={styles.PerkMegaModal__brandButton}
                    >
                      Go to {props.brand.brandName}
                    </Button>
                  )}
                </div>
              </div>
              {/* <p>by: </p> */}
            </div>
            <div className="col-lg-6">
              <div className={styles.PerkMegaModal__rightColumnWrapper}>
                <img
                  className={styles.PerkMegaModal__perkImage}
                  src={props.perkImageUrl}
                />
                {props.perk.perkDisclaimer && (
                  <div>
                    <p className={styles.PerkMegaModal__disclaimer}>
                      {props.perk.perkDisclaimer}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {props.perk.qualificationGroup && (
                <PerkGroupDisplay
                  groupId={props.perk.qualificationGroup}
                  selectedPerkId={props.perkId}
                  brandId={props.brandId}
                  currentUserHoldingCount={props.holdingData?.quantity}
                  currentPrice={props.holdingData?.value}
                  clickToUrl
                  splitEarly
                  brand={props.brand}
                  showBrandButton
                />
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default PerkMegaModalUI;
