import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

type Props = {
  markdownText: string;
  className?: string;
};

const Markdown = (props: Props) => {
  return (
    <ReactMarkdown
      children={props.markdownText}
      rehypePlugins={[rehypeRaw]}
      className={props.className}
    />
  );
};

export default Markdown;
