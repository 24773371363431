import { GetNumberOfClaimsForPerk } from "$gql/queries/general/GetNumberOfClaimsForPerk.gen";
import React from "react";
import { useQueryBundle } from "source/hooks";
import styles from "./LimitedPerk.module.scss";

type Props = {
  perkId: string;
  limitedNumber: number;
};

const LimitedPerk = (props: Props) => {
  const numberOfClaims = useQueryBundle(GetNumberOfClaimsForPerk, {
    variables: {
      perkId: props.perkId,
    },
  });

  if (
    numberOfClaims.state === "DONE" &&
    props.limitedNumber <= numberOfClaims.data.getNumberOfClaimsForPerk
  ) {
    return (
      <div className={styles.limitedEdition}>
        <img src="/icons/star.png" className={styles.star} /> Sold Out
      </div>
    );
  }

  return (
    <div className={styles.limitedEdition}>
      <img src="/icons/star.png" className={styles.star} /> Limited
    </div>
  );
};

export default LimitedPerk;
