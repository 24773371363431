import { ClaimedPerkOrTierOptionArgs } from "$gql/types.gen";
import { PerkDocument } from "@tiicker/util/lib/contentful/types";
import Accordion from "components/Accordion/Accordion";
import { Entry } from "contentful";
import React from "react";
import styles from "./BundleOptions.module.scss";
import PerkOptions, { PerkOption } from "./PerkOptions";

type Props = {
  subPerks: Entry<PerkDocument>[];
  includes: any;
  selectedOptions: ClaimedPerkOrTierOptionArgs[];
  setSelectedOptions: (options: ClaimedPerkOrTierOptionArgs[]) => void;
  setPerkOptions: (options: PerkOption[]) => void;
  loadedPerkOptions: PerkOption[];
  canClaimPerk: boolean;
};

const BundleOptions = (props: Props) => {
  return (
    <Accordion
      singleOpen
      classNameOptions={{
        panel: styles.subPerkPanel,
        openPanel: styles.subPerkPanel_open,
        closedPanel: styles.subPerkPanel_close,
        toggle: styles.toggle,
        toggleClosed: styles.toggle_close,
        toggleOpen: styles.toggle_open,
        outerTitle: styles.subPerkTitle,
        // content: styles.content,
      }}
      // defaultOpenKey={`perk-${props.subPerks[0].sys.id}`}
    >
      {props.subPerks.map((perk, i) => (
        <Accordion.Panel
          key={`perk-${perk.sys.id}`}
          titleContent={perk.fields.title}
        >
          <div>
            <p className={styles.subPerkDescription}>
              {perk.fields.description}
            </p>
            {props.canClaimPerk && (
              <PerkOptions
                perk={perk}
                includes={props.includes}
                selectedOptions={props.selectedOptions}
                setSelectedOptions={props.setSelectedOptions}
                perkId={perk.sys.id}
                setPerkOptions={props.setPerkOptions}
                loadedPerkOptions={props.loadedPerkOptions}
              />
            )}
          </div>
        </Accordion.Panel>
      ))}
    </Accordion>
  );
};

export default BundleOptions;
