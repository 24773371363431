import classNames from "classnames";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useCurrentWidth } from "source/hooks";
import styles from "./Accordion.module.scss";

interface PanelProps {
  isLoading?: boolean;
  isOpen?: boolean;
  onToggle?(): void;
  title?: string;
  titleContent?: React.ReactNode;
  classPrefix?: string;
  id?: string;
  classNameOptions?: ClassNameOptions;
}

export interface ClassNameOptions {
  container?: string;
  panel?: string;
  openPanel?: string;
  closedPanel?: string;
  outerTitle?: string;
  innerTitle?: string;
  loadingTitle?: string;
  toggle?: string;
  toggleOpen?: string;
  toggleClosed?: string;
  content?: string;
}

const Panel: React.FC<PanelProps> = (props) => {
  const openText = props.isOpen ? "open" : "closed";
  const panelRef = useRef<HTMLDivElement>(null);
  const [childHeight, setChildHeight] = useState(0);
  const classPrefix = props.classPrefix || "AccordionPanel";
  const width = useCurrentWidth(10);

  useEffect(() => {
    const panelChild = panelRef.current?.firstChild as
      | HTMLDivElement
      | undefined;

    if (!panelChild || !panelChild.getBoundingClientRect) {
      return;
    }

    const height = panelChild.getBoundingClientRect().height;
    setChildHeight(height || 0);
  }, [panelRef, props.isOpen, width]);

  const style: CSSProperties = {
    height: props.isOpen ? childHeight : 0,
  };

  const panelClassNames = classNames(
    props.classNameOptions?.panel,
    props.isOpen
      ? props.classNameOptions?.openPanel
      : props.classNameOptions?.closedPanel,
    classPrefix && `${classPrefix}__panel ${classPrefix}__panel--${openText}`
  );

  const outerTitleClassNames = classNames(
    props.classNameOptions?.outerTitle,
    classPrefix && `${classPrefix}__title`
  );

  const loadingTitleClassNames = classNames(
    props.classNameOptions?.loadingTitle,
    classPrefix && `${classPrefix}__loadingTitle`
  );

  const innerTitleClassNames = classNames(
    props.classNameOptions?.innerTitle,
    classPrefix && `${classPrefix}__innerTitle`
  );

  const toggleClassNames = classNames(
    props.classNameOptions?.toggle,
    props.isOpen
      ? props.classNameOptions?.toggleOpen
      : props.classNameOptions?.toggleClosed,
    classPrefix && `${classPrefix}__toggle ${classPrefix}__toggle--${openText}`
  );

  const contentClassNames = classNames(
    props.classNameOptions?.content,
    classPrefix && `${classPrefix}__content`,
    styles.AccordionPanel__collapseSection
  );

  return (
    <div data-accordian-panel={props.id} className={panelClassNames}>
      <div
        className={outerTitleClassNames}
        onClick={(e) => !props.isLoading && props.onToggle && props.onToggle()}
      >
        {props.isLoading ? (
          <div className={loadingTitleClassNames}></div>
        ) : props.titleContent ? (
          props.titleContent
        ) : (
          <span className={innerTitleClassNames}>{props.title}</span>
        )}
        <span className={toggleClassNames}></span>
      </div>
      <div ref={panelRef} style={style} className={contentClassNames}>
        {props.children}
      </div>
    </div>
  );
};

interface AccordionProps {
  classPrefix?: string;
  singleOpen?: boolean;
  overriddenSelectedKey?: string;
  scrollToKey?: string;
  classNameOptions?: ClassNameOptions;
  defaultOpenKey?: string;
  openAll?: boolean;
}

interface AccordionInterface extends React.FC<AccordionProps> {
  Panel: typeof Panel;
}

const Accordion: AccordionInterface = (props) => {
  const [panelHash, setPanelHash] = useState<{ [key: string]: boolean }>({});
  const [currentOpen, setCurrentOpen] = useState<string | undefined>(
    props.defaultOpenKey
  );

  useEffect(() => {
    if (
      props.overriddenSelectedKey &&
      props.overriddenSelectedKey !== currentOpen
    ) {
      setCurrentOpen(props.overriddenSelectedKey);
    }
  }, [props.overriddenSelectedKey]);

  useEffect(() => {
    if (!props.scrollToKey) {
      return;
    }

    const element = document.querySelector<HTMLDivElement>(
      `[data-accordian-panel=${props.scrollToKey}]`
    );

    if (!element) {
      return;
    }

    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [props.scrollToKey]);

  useEffect(() => {
    React.Children.map(
      props.children,
      (panel: React.ReactElement<PanelProps>, index: number) => {
        if (props.openAll) {
          const key = String(panel.key || index);
          if (panelHash[key] === undefined) {
            setPanelHash({
              ...panelHash,
              [key]: true,
            });
          }
        }
      }
    );
  }, [props.children, props.openAll]);

  const getPanels = () => {
    return React.Children.map(
      props.children,
      (panel: React.ReactElement<PanelProps>, index: number) => {
        const key = String(panel.key || index);

        const onToggle = () => {
          const newKey = currentOpen === key ? undefined : key;
          setCurrentOpen(newKey);

          setPanelHash({
            ...panelHash,
            [key]: !panelHash[key],
          });
        };

        return React.cloneElement(panel, {
          isOpen: props.singleOpen ? currentOpen === key : !!panelHash[key],
          onToggle,
          classPrefix: props.classPrefix,
          id: key,
          classNameOptions: props.classNameOptions,
        });
      }
    );
  };

  return (
    <div
      className={classNames(
        props.classNameOptions?.container,
        props.classPrefix
      )}
    >
      {getPanels()}
    </div>
  );
};

Accordion.Panel = Panel;

export default Accordion;
