import { PerkDocument } from "@tiicker/util/lib/contentful/types";
import classnames from "classnames";
import {
  getHoldingRequirement,
  getQualificationDetails,
  getQualificationText,
} from "components/BrandPage/PerksSection/GroupedPerks/GroupedPerks";
import Markdown from "components/Markdown/Markdown";
import React from "react";
import styles from "./PerkDetails.module.scss";

type Props = {
  alreadyClaimedPerk: boolean;
  canClaimPerk: boolean;
  sharesHeld: number;
  perk: PerkDocument;
  claimCount: number;
};

const PerkDetails = (props: Props) => {
  const limitedEditionPerk =
    props.perk.limitedQuantityNumber && props.perk.limitedQuantityNumber > 0;

  return (
    <>
      {(!props.alreadyClaimedPerk || props.canClaimPerk) && (
        <>
          <div className={styles.detailColumnWrapper}>
            <div>
              <p className={styles.detailTitle}>Shares Held</p>
              <p className={styles.detailValue}>{props.sharesHeld}</p>
            </div>

            <div>
              <p className={styles.detailTitle}>
                {getQualificationText(props.perk)}
              </p>
              <p
                className={classnames(
                  styles.detailValue,
                  limitedEditionPerk && styles.detailValue__right
                )}
              >
                {getQualificationDetails(props.perk)}
              </p>
            </div>

            {!limitedEditionPerk && (
              <div>
                <p className={styles.detailTitle}>Must Hold For</p>
                <p className={styles.detailValue}>
                  {getHoldingRequirement(props.perk)}
                </p>
              </div>
            )}
          </div>
          {limitedEditionPerk && (
            <div className={styles.detailColumnWrapper}>
              <div>
                <p className={styles.detailTitle}>Must Hold For</p>
                <p className={styles.detailValue}>
                  {getHoldingRequirement(props.perk)}
                </p>
              </div>
              <div>
                <p className={styles.detailTitle}>Limited Edition</p>
                <p
                  className={classnames(
                    styles.detailValue,
                    styles.detailValue__right
                  )}
                >
                  {props.perk.limitedQuantityText ??
                    `${
                      (props.perk.limitedQuantityNumber ?? 0) - props.claimCount
                    } Remaining`}
                </p>
              </div>
            </div>
          )}
        </>
      )}

      <Markdown
        markdownText={props.perk.description}
        className={styles.description}
      />
    </>
  );
};

export default PerkDetails;
