import styles from "./CouponCodeResults.module.scss";
import React from "react";
import Copy from "/public/icons/copy.svg";
import Markdown from "components/Markdown/Markdown";
import { CopyToClipboard } from "react-copy-to-clipboard";

type Props = {
  couponCode: string;
  redemptionDetails: string;
  link?: string;
  title?: string;
  perkTitle?: string;
  showPerkTitle?: boolean;
};

const CouponCodeResults = (props: Props) => (
  <div className={styles.container}>
    {props.perkTitle && props.showPerkTitle && (
      <p className={styles.perkTitle}>{props.perkTitle}</p>
    )}
    <div className={styles.codeContainer} data-cy="CouponCodeContainer">
      <p className={styles.codeText}>{props.title ?? "Coupon Code"}</p>
      <div className={styles.copyContainer}>
        <p className={styles.couponCode}>{props.couponCode}</p>
        <CopyToClipboard text={props.couponCode}>
          <Copy className={styles.copyIcon} />
        </CopyToClipboard>
      </div>
    </div>
    {props.link && (
      <>
        <p className={styles.link}>Redemption Url</p>
        <a href={props.link}>{props.link}</a>
      </>
    )}
    <Markdown
      className={styles.details}
      markdownText={props.redemptionDetails}
    />
  </div>
);

export default CouponCodeResults;
