import { boolean } from "@storybook/addon-knobs";
import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import styles from "./InsightShare.module.scss";

export interface InsightShareProps {
  title: string;
  slug: string;
  urlOverride?: string;
  shareText?: string;
  center?: boolean;
  removeShareText?: boolean;
}

const InsightShare = (props: InsightShareProps) => {
  const url = props.urlOverride
    ? props.urlOverride
    : `${typeof window !== "undefined" && window.location.host}/insights/${props.slug
    }`;

  return (
    <div className={styles.SocialShare}>
      <div className={props.center ? styles.SocialShare__group__center : styles.SocialShare__group}>
        {!props?.removeShareText && (
          <>
            <p>{props.shareText ?? "Share"}</p>
          </>
        )}

        <FacebookShareButton url={url} quote={props.title}>
          <FacebookIcon
            bgStyle={{ fill: "#000" }}
            size={42}
            round
            className={styles.icon}
          />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={props.title}>
          <TwitterIcon
            bgStyle={{ fill: "#000" }}
            size={42}
            round
            className={styles.icon}
          />
        </TwitterShareButton>
        <LinkedinShareButton url={url} title={props.title}>
          <LinkedinIcon
            bgStyle={{ fill: "#000" }}
            size={42}
            round
            className={styles.icon}
          />
        </LinkedinShareButton>
        <EmailShareButton url={url} subject={props.title}>
          <EmailIcon
            bgStyle={{ fill: "#000" }}
            size={42}
            round
            className={styles.icon}
          />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default InsightShare;
