import ConnectBrokerageModal from "components/PlaidLink/ConnectBrokerageModal/ConnectBrokerageModal";
import { useUser } from "components/UserContext/UserContext";
import { useState } from "react";
import styles from "./ConnectAccounts.module.scss";

type Props = {};

const ConnectAccounts = (props: Props) => {
  const userContext = useUser();
  const [openBrokerageConnect, setOpenBrokerageConnect] =
    useState<boolean>(false);
  const connectAccount = () => {
    setOpenBrokerageConnect(true);
  };

  return (
    <>
      <div className={styles.container}>
        <p className={styles.connectAccounts}>
          In order to qualify for any perks on TiiCKER, please make sure to{" "}
          <a onClick={connectAccount}>connect your brokerage accounts.</a>
        </p>
        <p className={styles.disclaimer}>
          Please note: It can take up to 24 hours for new holdings to sync with
          TiiCKER.
        </p>
      </div>
      <ConnectBrokerageModal
        visible={openBrokerageConnect}
        onClose={() => setOpenBrokerageConnect(false)}
        openPlaid={() => userContext.openPlaid()}
      />
    </>
  );
};

export default ConnectAccounts;
