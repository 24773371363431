import styles from "./NotQualified.module.scss";

type Props = {
  requirementText: string;
  holdingRequirement: string;
  brandTicker: string;
  brandName: string;
};

const NotQualified = (props: Props) => {
  return (
    <div className={styles.container}>
      <p className={styles.requirement}>
        {props.requirementText}{" "}
        {props.requirementText.trim().toLowerCase() === "1 share"
          ? " is"
          : " are"}{" "}
        required to{" "}
        {props.holdingRequirement.toLowerCase() !== "no requirement"
          ? `be held for at least ${props.holdingRequirement} to`
          : ""}{" "}
        claim this perk.{" "}
        <a href={`/${props.brandTicker}`}>Visit {props.brandName}</a>
      </p>
      <p className={styles.disclaimer}>
        Please note: It can take up to 24 hours for new holdings to sync with
        TiiCKER.
      </p>
    </div>
  );
};

export default NotQualified;
