import React from "react";
import styles from "./GroupDetails.module.scss";
import { toWords } from "number-to-words";
import { formatQuantity, formatCurrency } from "@tiicker/util/lib/formats";
import classnames from "classnames";
import ManualShareUpload from "./ManualShareUpload";
import { BrandDetailDocument } from "@tiicker/util/lib/contentful/types";
import { UserFragment } from "$gql/fragments/general/User.gen";
import Button from "components/core/Button/Button";

type Props = {
  allAvailable: boolean;
  isOnlyGroup: boolean;
  numOfPerks: number;
  holdingCount: number;
  currentPrice: number;
  keyValue?: string;
  splitEarly?: boolean;
  brand?: BrandDetailDocument;
  includes: any;
  user: UserFragment | null | undefined;
  showBrandButton?: boolean;
};

const GroupDetails = (props: Props) => {
  const onlyGroupTitle = "Available Perks";
  const allAvailableTitle = "Select any";
  const groupTitle = `Select one of ${toWords(props.numOfPerks)}`;

  const title =
    props.isOnlyGroup && props.allAvailable
      ? onlyGroupTitle
      : props.allAvailable
      ? allAvailableTitle
      : groupTitle;

  return (
    <div
      className={classnames(styles.container, {
        [styles.splitEarly]: props.splitEarly,
      })}
      key={`group-details-${props.keyValue}`}
    >
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>
        {props.brand &&
        (props.brand.tickerSymbol.toLowerCase() === "lgf.a" ||
          props.brand.tickerSymbol.toLowerCase() === "lgf.b")
          ? "Select a perk that you qualify for or continue browsing to see additional Lionsgate perk options."
          : "Select a perk that you qualify for or buy more shares to qualify for a better perk."}
      </p>
      {props.brand && props.showBrandButton && (
        <Button
          arrow
          type="secondary"
          href={`/brand/${props.brand.tickerSymbol}`}
          className={styles.goToBrandButton}
        >
          Go to {props.brand.brandName}
        </Button>
      )}
      {props.brand && props.brand.showManualStockUpload && props.user && (
        <ManualShareUpload
          userId={props.user.id}
          brand={props.brand}
          includes={props.includes}
        />
      )}
      {props.holdingCount !== 0 && (
        <div className={styles.requirementContainer}>
          <h5>Your Positions</h5>
          <div className={styles.detailContainer}>
            <div>
              <p className={styles.detailTitle}>Shares Held</p>
              <p className={styles.detail}>
                {formatQuantity(props.holdingCount)}
              </p>
            </div>
            <div>
              <p className={styles.detailTitle}>Share Value</p>
              <p className={styles.detail}>
                {formatCurrency(props.currentPrice * props.holdingCount)}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupDetails;
