import React from "react";
import { GetUserPerkCertificateUrl } from "$gql/queries/general/GetUserPerkCertificateUrl.gen";
import { useQuery } from "@apollo/client";
import LoadingBlock from "components/Loading/LoadingBlock";
import { useInterval } from "components/PlaidLink/PollPlaidStatus";
import Link from "next/link";
import Button from "components/core/Button/Button";
import styles from "./PollPerkCertificate.module.scss";

interface Props {
  userId: number;
  perkId: string;
}

const PollPerkCertificate = (props: Props) => {
  const result = useQuery(GetUserPerkCertificateUrl.Document, {
    variables: {
      perkId: props.perkId,
      userId: props.userId,
    },
  });

  useInterval(
    () => {
      result.refetch({ perkId: props.perkId, userId: props.userId });
    },
    !result.data || !result.data.getUserPerkCertificateUrl ? 2000 : null
  );

  const thumbnail =
    result.data && result.data.getUserPerkCertificateUrl
      ? `${result.data.getUserPerkCertificateUrl.split(".png")[0]}-thumb.png`
      : "";

  return (
    <>
      {result.data && result.data.getUserPerkCertificateUrl ? (
        <>
          <a
            href={result.data?.getUserPerkCertificateUrl}
            download
            target="_blank"
          >
            <img className={styles.certificateImage} src={thumbnail} />
          </a>
          <div className={styles.downloadButtonContainer}>
            <Button
              className={styles.downloadButton}
              href={result.data.getUserPerkCertificateUrl}
            >
              Download
            </Button>
          </div>
        </>
      ) : (
        <div>
          <LoadingBlock />
          <h5>Please wait a moment while we generate your collectible.</h5>
        </div>
      )}
    </>
  );
};

export default PollPerkCertificate;
