import { PerkDocument } from "@tiicker/util/lib/contentful/types";
import Accordion from "components/Accordion/Accordion";
import Markdown from "components/Markdown/Markdown";
import { Entry } from "contentful";
import React from "react";
import styles from "./BundleClaimedPerkDisplay.module.scss";
import ClaimedPerkDisplay from "./ClaimedPerkDisplay";

type Props = {
  subPerks: Entry<PerkDocument>[];
  tickerSymbol: string;
  brandName: string;
  userId: number;
  perk: PerkDocument;
};

const BundleClaimedPerkDisplay = (props: Props) => {
  return (
    <>
      {props.perk.redemptionDetails && (
        <Markdown markdownText={props.perk.redemptionDetails} />
      )}
      <Accordion
        classNameOptions={{
          panel: styles.subPerkPanel,
          openPanel: styles.subPerkPanel_open,
          closedPanel: styles.subPerkPanel_close,
          toggle: styles.toggle,
          toggleClosed: styles.toggle_close,
          toggleOpen: styles.toggle_open,
          outerTitle: styles.subPerkTitle,
        }}
      >
        {props.subPerks.map((perk) => (
          <Accordion.Panel
            key={`claimed-perk-${perk.sys.id}`}
            titleContent={perk.fields.title}
          >
            <ClaimedPerkDisplay
              perkId={perk.sys.id}
              userId={props.userId}
              perk={perk.fields}
              tickerSymbol={props.tickerSymbol}
              brandName={props.brandName}
              className={styles.perkDisplayClassName}
            />
          </Accordion.Panel>
        ))}
      </Accordion>
    </>
  );
};

export default BundleClaimedPerkDisplay;
