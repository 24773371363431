import styles from "./StorePerkResults.module.scss";
import React, { useState } from "react";
import OpenCompanyStore from "components/OpenCompanyStore/OpenCompanyStore";
import Button from "components/core/Button/Button";

type Props = {
  redemptionDetails: string | undefined;
  tickerSymbol: string;
  storeUrl: string;
  brandName: string;
};

const StorePerkResults = (props: Props) => {
  const [openCompanyStore, setOpenCompanyStore] = useState<boolean>(false);

  const openStore = () => {
    setOpenCompanyStore(true);
  };

  return (
    <div className={styles.container}>
      {props.redemptionDetails && (
        <p className={styles.details}>{props.redemptionDetails}</p>
      )}
      {props.redemptionDetails === undefined && (
        <p className={styles.details}>
          Credits have been applied to your account in the {props.brandName}{" "}
          store. As you select your item you may apply your credit and also
          purchase additional credits to use in the store.
        </p>
      )}
      <p className={styles.details}>
        For any questions, please contact us at{" "}
        <a href="mailto:perks@tiicker.com">perks@tiicker.com</a>.
      </p>
      <Button onClick={openStore}>Visit Company Store</Button>

      {openCompanyStore && (
        <OpenCompanyStore
          tickerSymbol={props.tickerSymbol}
          onClose={() => setOpenCompanyStore(false)}
          productUrl={props.storeUrl}
        />
      )}
    </div>
  );
};

export default StorePerkResults;
