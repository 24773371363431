import { GetStoreAutoLoginToken } from "$gql/queries/general/GetStoreAutoLoginToken.gen";
import { useQueryBundle } from "source/hooks";
import React, { useEffect } from "react";

type props = {
  tickerSymbol: string;
  productUrl?: string | null;
  onClose: () => void;
};

const OpenCompanyStore: React.FC<props> = (props) => {
  const getAutoLoginToken = useQueryBundle(GetStoreAutoLoginToken, {
    variables: { tickerSymbolOrSubdomain: props.tickerSymbol },
  });

  useEffect(() => {
    if (getAutoLoginToken.state === "DONE") {
      if (getAutoLoginToken.data.getStoreAutoLoginToken) {
        const storeUrl =
          props.productUrl ||
          getAutoLoginToken.data.getStoreAutoLoginToken.storeUrl;

        window.open(
          `${storeUrl}?auto=${getAutoLoginToken.data.getStoreAutoLoginToken.token}`,
          "_blank"
        );
        props.onClose();
      }
    }
  }, [getAutoLoginToken.state, getAutoLoginToken, props.onClose]);

  return <></>;
};
export default OpenCompanyStore;
